import { cilLockLocked, cilVerticalAlignTop } from '@coreui/icons'
import CIcon from '@coreui/icons-react'
import {
    CAlert,
    CBadge,
    CButton,
    CCard,
    CCardBody,
    CCardLink,
    CCardSubtitle,
    CCardText,
    CCardTitle,
    CCol,
    CLink,
    CRow,
    CSpinner,
    CTable,
    CTableBody,
    CTableDataCell,
    CTableFoot,
    CTableHead,
    CTableHeaderCell,
    CTableRow,
} from '@coreui/react-pro'
import moment from 'moment'
import { useParams } from 'react-router'
import { userHasSomePermissions } from '../../auth/authUtils'
import { useAuthenticatedUser } from '../../auth/authenticationApi'
import OrderStatusBadge from '../components/OrderStatusBadge'
import PaymentDetailsCard from '../components/PaymentDetailsCard'
import { useExportOrderToWmsMutation, useGetOrderQuery } from '../store/orderAdminApi'
import Toolbar from '../../common/components/Toolbar'
import React from 'react'
import { executeMutation } from '../../common/utils/apiUtils'
import { useDispatch } from 'react-redux'

interface OrderDetailsPageRouteParams {
    orderId: string
}

function OrderDetailsPage() {
    const { orderId } = useParams<OrderDetailsPageRouteParams>()
    const user = useAuthenticatedUser()
    const userCanEditOrder = userHasSomePermissions(user, 'orders.write')
    const userCanViewCustomer = userHasSomePermissions(user, 'customers.read')
    const { data: order, isLoading, isError, isSuccess, error } = useGetOrderQuery(orderId)
    const [exportOrder, { isLoading: isExporting }] = useExportOrderToWmsMutation()
    const dispatch = useDispatch()
    const handleExport = () => {
        executeMutation(exportOrder(orderId), dispatch, 'Ordre eksportert', 'Kunne ikke eksportere ordre', () => {})
    }
    return (
        <div>
            {isLoading && <CSpinner></CSpinner>}
            {isError && <CAlert color={'danger'}>{error}</CAlert>}
            {isSuccess && order && (
                <>
                    <h1>
                        <CRow>
                            <CCol>
                                Ordre #{order.id}({order.externalId}) <OrderStatusBadge orderStatus={order.status} />
                                {order.locked && <CIcon size={'xxl'} icon={cilLockLocked} />}
                            </CCol>
                        </CRow>
                    </h1>
                    <Toolbar>
                        <div>
                            <CButton variant={'outline'} disabled={isExporting} onClick={handleExport}>
                                <CIcon className={'mr-1'} icon={cilVerticalAlignTop} size="sm" />
                                {isExporting ? 'Eksporterer...' : 'Eksporter'}
                            </CButton>
                        </div>
                    </Toolbar>
                    <CRow xs={{ gutterY: 3 }}>
                        <CCol>
                            <CCard>
                                <CCardBody>
                                    <CCardTitle>Kunde #{order.customer.customerId}</CCardTitle>
                                    <CCardSubtitle className="mb-1/2 text-medium-emphasis">
                                        {order.customer.name}
                                    </CCardSubtitle>

                                    <CCardText>{order.customer.authenticated ? 'Innlogget' : 'Utlogget'}</CCardText>
                                    {userCanViewCustomer && (
                                        <CCardLink href={`/kunde/${order.customer.customerId}`}>
                                            <CButton>Vis kunde</CButton>
                                        </CCardLink>
                                    )}
                                </CCardBody>
                            </CCard>
                        </CCol>
                        <CCol>
                            <CCard>
                                <CCardBody>
                                    <CCardTitle>Mottaker</CCardTitle>
                                    <CCardSubtitle className="mb-1/2 text-medium-emphasis">
                                        {order.address.firstName} {order.address.lastName}
                                    </CCardSubtitle>
                                    <CCardText component={'div'}>
                                        <address>
                                            {order.address.street} {order.address.streetNumber}
                                            <br />
                                            {order.address.zipCode} {order.address.zipName}
                                        </address>
                                        <p>
                                            Distribusjonsdato <b>{moment(order.deliveryDate).format('Do MMMM YY')}</b>
                                        </p>
                                    </CCardText>
                                    <CCardLink href="#">
                                        {!order.locked && <CButton color={'warning'}>Endre</CButton>}
                                    </CCardLink>
                                </CCardBody>
                            </CCard>
                        </CCol>
                        <CCol sm={12}>
                            <CCard>
                                <CCardBody>
                                    <CCardTitle>Ordrelinjer</CCardTitle>
                                    <CTable>
                                        <CTableHead>
                                            <CTableHeaderCell>Ekstern produktid</CTableHeaderCell>
                                            <CTableHeaderCell>Antall</CTableHeaderCell>
                                            <CTableHeaderCell>Vendor</CTableHeaderCell>
                                            <CTableHeaderCell>Varenavn</CTableHeaderCell>
                                            <CTableHeaderCell>Betalt</CTableHeaderCell>
                                        </CTableHead>
                                        <CTableBody>
                                            {order.orderLines.map((ol) => {
                                                return (
                                                    <CTableRow key={ol.productItemId}>
                                                        <CTableDataCell>
                                                            <CLink
                                                                href={`https://morgenlevering.no/product/${ol.externalProductId}`}
                                                                target={'_blank'}
                                                            >
                                                                {ol.externalProductId}
                                                            </CLink>
                                                        </CTableDataCell>
                                                        <CTableDataCell>{ol.quantity}</CTableDataCell>
                                                        <CTableDataCell>{ol.product?.vendor}</CTableDataCell>
                                                        <CTableDataCell>
                                                            {ol.name}{' '}
                                                            {ol.product && (
                                                                <img
                                                                    alt={ol.name}
                                                                    height={24}
                                                                    src={ol.product.imageUrl}
                                                                />
                                                            )}
                                                        </CTableDataCell>
                                                        <CTableDataCell>{ol.totalPrice.gross},-</CTableDataCell>
                                                    </CTableRow>
                                                )
                                            })}
                                        </CTableBody>
                                        <CTableFoot>
                                            <CTableRow>
                                                <CTableDataCell>Total</CTableDataCell>
                                                <CTableDataCell></CTableDataCell>
                                                <CTableDataCell></CTableDataCell>
                                                <CTableDataCell></CTableDataCell>
                                                <CTableDataCell>{order.calculatedPrice},-</CTableDataCell>
                                            </CTableRow>
                                        </CTableFoot>
                                    </CTable>
                                </CCardBody>
                            </CCard>
                        </CCol>
                        <CCol sm={6}>
                            <PaymentDetailsCard orderId={order.id + ''} />
                        </CCol>
                        <CCol>
                            <CCard>
                                <CCardBody>
                                    <CCardTitle>
                                        <CRow>
                                            <CCol>Levering</CCol>
                                            <CCol sm={3}>
                                                <CBadge color="success">Levert</CBadge>
                                            </CCol>
                                        </CRow>
                                    </CCardTitle>
                                    <CCardText>
                                        <p>Bookes {moment(order.bookingTime).format('LLLL')}</p>
                                    </CCardText>
                                    <CCardLink href="#">Vis i Flow</CCardLink>
                                    <CCardLink href="#">Vis i DI</CCardLink>
                                </CCardBody>
                            </CCard>
                        </CCol>
                    </CRow>
                </>
            )}
        </div>
    )
}

export default OrderDetailsPage
